// $primary: #a51616;
$primary: #FF0000;

/* MAIN COLOR */

$secondary: #000;

/* SECONDARY COLOR */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;

/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;

/* ======== GENERAL & OVERRIDES ======== */

body {
    background: url('../img/backdrop-bg.png');
    background-repeat: repeat;
    background-attachment: fixed;
}

.top-pad {
    margin-top: 25px;
    @media (max-width: 767px) {
        margin-top: 50px;
    }
}

.container {
    @media (max-width: 1440px) {
        width: 100% !important;
    }
    @media (max-width: 1024px) {
        width: 100% !important;
    }
    @media (max-width: 767px) {
        width: 100% !important;
    }
}

.flash {
    display: none;
}

body {
    background: url('../img/backdrop-bg.png');
    background-repeat: repeat;
    background-attachment: fixed;
}

.wrapper {
    padding-left: 240px !important;
    @media (max-width: 767px) {
        padding-left: 0px !important;
    }
}

.sticky-wrapper,
.is-sticky {
    height: auto !important;
    @media (max-width: 767px) {
        height: 75px !important;
    }
}

.navbar {
    margin: 0px !important;
}

h1,
h2,
h3 {
    font-family: 'Ultra', serif;
    letter-spacing: 2px;
}

a,
p,
ol,
ul {
    font-family: 'Alegreya Sans SC', sans-serif;
}

nav {
    z-index: 10;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}


/* ======== TERMS & PRIVACY ======== */

.section-terms-overlay {
    background-color: rgba(255, 255, 255, 1);
    @media (max-width: 767px) {
        padding-left: 0px;
    }
}

.terms-privacy p {
    font-size: 1.5em;
}

.terms-privacy h2 {
    font-size: 2em;
    color: $primary;
}

.terms-privacy h3 {
    font-size: 1.85em;
    color: $primary;
}

.terms-privacy ol,
li {
    font-size: 1.25em;
}


/* ======== NAVIGATION ======== */

.navbar-brand {
    background: url('../img/logo.png');
    background-repeat: no-repeat;
    margin-top: 20px;
    width: 220px;
    height: 220px;
    margin-left: 5px;
    @media (max-width: 846px) {
        background: url('../img/logoMed.png');
        background-repeat: no-repeat;
        width: 125px;
        height: 125px;
        margin-top: -1px;
        margin-bottom: 25px;
        margin-left: 55px;
    }
    @media (max-width: 767px) {
        background: url('../img/logo-small.png');
        background-repeat: no-repeat;
        width: 220px;
        height: 75px;
        margin-top: -1px;
        margin-left: 0px;
        margin-bottom: 0px;
    }
}

.navbar-nav {
    margin-top: 25px;
    text-align: left;
    float: none;
    @media (max-width: 991px) {
        text-align: left;
        margin-top: 0px;
        margin-top: -5px;
    }
    @media (max-width: 767px) {
        text-align: center;
        margin-top: 0px;
    }
}

.navbar-fixed-left {
    width: 240px !important;
    position: fixed;
    border-radius: 0;
    height: 100%;
    background-color: white;
    border: 0px;
    box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.2);
    background: rgb(255, 255, 255);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* IE6-9 */
    @media (max-width: 1440px) {
        background: rgb(255, 255, 255);
        /* Old browsers */
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        /* IE6-9 */
    }
    @media (max-width: 1024px) {
        background: rgb(255, 255, 255);
        /* Old browsers */
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        /* IE6-9 */
    }
    @media (max-width: 767px) {
        width: 100% !important;
        height: 75px;
        position: fixed;
        border-radius: 0;
        background-color: white;
        border: 0px;
        box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.2);
        background: rgb(255, 255, 255);
        /* Old browsers */
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        /* IE6-9 */
        margin-top: 0px;
        z-index: 1;
    }
}

.navbar-right {
    float: none;
    margin-right: 0px;
    @media (max-width: 767px) {
        margin-right: -15px;
    }
}

.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
    @media (max-width: 388px) {
        margin-top: 14px;
    }
    @media (max-width: 767px) {
        padding: 10px;
    }
}

.navbar-fixed-left .navbar-nav>li {
    float: none;
    /* Cancel default li float: left */
    width: 240px;
    font-size: 1.5em;
    border-bottom: 1px solid $secondary;
    @media (max-width: 767px) {
        text-align: center;
        height: 45px;
        display: inline-block;
        align-items: center;
        width: 100%;
    }
}

.navbar-fixed-left .navbar-nav>li>a {
    margin-top: -10px;
    color: $wht;
    font-weight: bold;
    text-shadow: 1px 1px $primary;
    padding-left: 40px;
    outline: none;
    text-align: left;
    @media (max-width: 767px) {
        height: 45px;
        display: inline-block;
        padding-left: 30px;
        font-size: .75em;
        margin-top: 0px;
        text-align: center;
    }
    &:visited {
        color: $wht;
    }
    &:hover {
        color: $secondary;
    }
}

.navbar-collapse {
    width: 100%;
    //   margin-top: 18px;
    background: transparent;
    padding-right: 0px;
    padding-left: 0px;
    @media (max-width: 767px) {
        border-bottom: 2px solid $primary;
        padding-left: 15px;
        padding-right: 15px;
        background-color: $primary;
    }
}

.glyphicon {
    font-size: 25px;
    padding-right: 10px;
    @media (max-width: 767px) {
        display: none;
    }
}

// /* On using dropdown menu (To right shift popuped) */
// .navbar-fixed-left .navbar-nav > li > .dropdown-menu {
//   margin-top: -50px;
//   margin-left: 140px;
// }
.logo {
    @media (max-width: 767px) {
        width: 100%;
        display: block;
        margin: 0 auto;
        max-width: 115px;
        padding-top: 5px;
    }
}


/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited {
    background-color: $secondary;
    border-color: $primary;
    color: $primary;
    padding: 10px 20px;
    font-size: 20px;
    text-transform: uppercase;
    border-radius: 5px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
}

.btn-download:hover {
    background-color: $primary;
    color: $wht;
    border-color: $wht;
}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
    margin-top: 15px;
}


/* =========== HEADER ========== */

.header {
    background-image: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-position: 80% 50%;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media (max-width: 1440px) {
        background-position: 60% 20%;
        padding: 10px 10px;
    }
    @media (max-width: 1199px) {
        background-position: 50% 20%;
        padding: 10px 10px;
    }
    @media (max-width: 767px) {
        background-position: 51% 10%;
        background-image: none;
    }
}

.header-overlay {
    background-color: rgba(31, 31, 31, 0.5);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-bottom: 15px solid $secondary;
    padding: 50px;
    @media (max-width: 1440px) {
        border: 1px solid #fff;
        padding: 45px;
    }
    @media (max-width: 1024px) {
        padding: 45px;
        border: 1px solid #fff;
    }
    @media (max-width: 767px) {
        padding: 25px;
        border: 1px solid #fff;
    }
}

.header h1 {
    color: $wht;
    text-shadow: 2px 2px $primary;
    font-size: 5em;
    @media (max-width: 1024px) {
        font-size: 4em;
    }
    @media (max-width: 767px) {
        font-size: 1.65em;
    }
}

.header p {
    color: $wht;
    font-size: 2em;
    line-height: 45px;
    letter-spacing: 2px;
    @media (max-width: 767px) {
        font-size: 1.25em;
        line-height: 35px;
    }
}

.header img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 350px;
    @media (max-width: 1024px) {
        max-width: 250px;
    }
}

.header .col-md-6 {
    width: 50%;
    @media (max-width: 1280px) {
        width: 100%;
    }
}


/* ========== SECTION ONE =============*/

.section-one {
    background: transparent;
    padding: 0px;
    text-align: center;
    @media (max-width: 1440px) {
        padding: 10px;
    }
}

.section-one-overlay {
    background-color: rgba(233, 0, 6, 0.65);
    padding: 75px 0px 50px 0px;
    box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.2), inset 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media (max-width: 767px) {
        padding: 15px 0px;
    }
}

.section-one h1 {
    color: $wht;
    font-size: 4em;
    text-shadow: 2px 2px $blk;
    padding-top: 15px;
    @media (max-width: 1024px) {
        font-size: 4em;
    }
    @media (max-width: 767px) {
        font-size: 3em;
    }
}

.section-one p {
    color: $wht;
    font-size: 2em;
    line-height: 45px;
    letter-spacing: 2px;
    @media (max-width: 767px) {
        font-size: 1.25em;
        line-height: 40px;
    }
}

.section-one img {
    display: block;
    margin: 0px auto;
    width: 100%;
    max-width: 500px;
    margin-top: 50px;
}

.text-container {
    background: $secondary;
    padding: 25px;
    border-radius: 12px;
    border: 8px double $wht;
    box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.2), inset 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#signUpForm {
    margin-top: 35px;
}


/* ========== SECTION TWO =============*/

.section-two {
    background-image: url('../img/lower-bg.jpg');
    background-repeat: no-repeat;
    background-position: 0% 50%;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-bottom: 5px solid $secondary;
    @media (max-width: 1440px) {
        padding: 10px;
        background-position: 80% 50%;
    }
    @media (max-width: 1024px) {
        padding: 10px;
        background-position: 45% 50%;
    }
    @media (max-width: 767px) {
        padding: 10px;
        background-position: 43% 50%;
    }
}

.section-two-overlay {
    background-color: rgba(31, 31, 31, .75);
    padding: 150px 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media (max-width: 1024px) {
        padding: 25px 0px;
    }
    @media (max-width: 767px) {
        padding: 0px;
    }
}

.section-two h1 {
    color: $wht;
    font-size: 4em;
    text-shadow: 1px 1px $primary;
    @media (max-width: 767px) {
        font-size: 2.5em;
    }
}

.section-two h2 {
    color: $wht;
    font-size: 2.5em;
    text-shadow: 1px 1px $secondary;
    @media (max-width: 1440px) {
        font-size: 2em;
    }
    @media (max-width: 1199px) {
        font-size: 1.5em;
    }
}

.section-two p {
    color: $wht;
    font-size: 2em;
    line-height: 50px;
    letter-spacing: 2px;
    @media (max-width: 767px) {
        font-size: 1.25em;
        line-height: 35px;
    }
}

.section-two img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 850px;
    margin-top: 50px;
    border: 10px solid #fff;
    box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.2), inset 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media (max-width: 1024px) {
        max-width: 300px;
        display: block;
        margin: 0px auto;
        width: 100%;
    }
}

.section-two .col-md-8 {
    width: 66.66666667%;
    @media (max-width: 1440px) {
        width: 100%;
    }
    @media (max-width: 1199px) {
        width: 100%;
    }
    @media (max-width: 991px) {
        width: 100%;
    }
}


/* ========== SECTION THREE =============*/

.section-three {
    background: transparent;
    padding: 0px;
    border-bottom: 5px solid $secondary;
    @media (max-width: 1440px) {
        padding: 10px;
        text-align: center;
    }
}

.section-three-overlay {
    background-color: rgba(203, 0, 6, .75);
    padding: 50px 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid $secondary;
}

.section-three h1 {
    color: $wht;
    font-size: 5em;
    text-shadow: 2px 2px $secondary;
    @media (max-width: 1440px) {
        font-size: 4em;
    }
    @media (max-width: 1024px) {
        font-size: 3em;
    }
    @media (max-width: 767px) {
        font-size: 2.5em;
    }
}

.section-three h3 {
    font-size: 3em;
    padding-bottom: 25px;
    padding-left: 95px;
    padding-top: 25px;
}

.section-three p {
    color: $wht;
    font-size: 2em;
    line-height: 50px;
    letter-spacing: 2px;
    @media (max-width: 767px) {
        font-size: 1.25em;
        line-height: 40px;
    }
}

.section-three img {
    width: 100%;
    max-width: 350px;
    border: 4px solid $secondary;
    @media (max-width: 1024px) {
        max-width: 350px;
        display: block;
        margin: 0px auto;
        width: 100%;
        margin-bottom: 50px;
    }
}

.section-three .col-md-6 {
    width: 50%;
    @media (max-width: 1199px) {
        width: 100%;
    }
}


/*=============== CTA =============== */

.cta {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,000000+100 */
    background: rgb(255, 255, 255);
    /* Old browsers */
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* IE6-9 */
    padding: 0px;
    border-bottom: 5px solid $secondary;
}

.cta h1 {
    color: $primary;
    text-shadow: 1px 1px $secondary;
    font-size: 3em;
}


/* ============ BUTTONS MODALS ============= */

@mixin btn1 {
    text-align: center;
    margin: 0 auto;
    border: 1px solid $primary;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    z-index: 1000;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
        border-bottom-left-radius: 13px;
        border-bottom-right-radius: 13px;
        h2 {
            text-align: center;
            font-size: 2em;
        }
        a {
            font-size: .63em;
        }
    }
    input {
        border: none;
        border-bottom: 1px solid darken($primary, 20%);
        text-align: center;
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn1;
        display: block;
        width: 75%;
    }
}

.modal-header {
    background: $primary;
    color: $wht;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.modal-footer {
    background: $primary;
    color: $wht;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 1.05em;
    a {
        color: $footerLinks;
        &:hover {
            color: lighten($footerLinks, 10%);
        }
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}